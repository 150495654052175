import React from 'react';
import { Link } from 'gatsby';

import { FooterWrapper, InnerFooter, EmojiWrapper } from './styled';

const Footer = () => (
  <FooterWrapper>
    <EmojiWrapper>
      🐼
    </EmojiWrapper>
    <InnerFooter>
      <span style={{ fontFamily: 'FuturaMedium' }}>
        Sebabytes © {new Date().getFullYear()}
      </span>
      <span>
        <Link to="/imprint" style={{ color: '#000', textDecoration: 'none' }}>
          Imprint
        </Link>
      </span>
    </InnerFooter>
  </FooterWrapper>
);

export default Footer;
