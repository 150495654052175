import styled from 'styled-components';

const FooterWrapper = styled.footer`
  margin-top: 120px;
  opacity: 0.46;
  background-color: #ffffff;

  @media only screen and (max-width: 768px) {
    margin-top: 60px;
  }
`;

const InnerFooter = styled.div`
  height: 100px;
  margin: 0 auto;
  max-width: 960px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;

  span {
    margin: 0px 10px;
  }
`;

const EmojiWrapper = styled.div`
  margin: 0 auto;
  max-width: 960px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 22px;
  font-size: 32px;
`;

export { FooterWrapper, InnerFooter, EmojiWrapper };
