import React from 'react';
import { Link } from 'gatsby';

import { HOME } from '@routing/paths';

import {
  InnerHeader,
  HeaderWrapper,
  LogoWrapper,
  VerticalCenterWrapper,
} from './styled';

const Header = () => (
  <HeaderWrapper>
    <InnerHeader>
      <LogoWrapper>
        <VerticalCenterWrapper style={{ marginRight: '12px' }}>
          <Link to={HOME} style={{ textDecoration: 'none' }}>
            <h4 style={{ color: 'rgb(80, 80, 80)' }}>
              
            </h4>
          </Link>
        </VerticalCenterWrapper>
      </LogoWrapper>
    </InnerHeader>
  </HeaderWrapper>
);

export default Header;
