import styled from 'styled-components';

const InnerHeader = styled.div`
  margin: 0 auto;
  max-width: 960px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    padding: 0 16px;
  }
`;

const HeaderWrapper = styled.div`
  height: 100px;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
`;

const VerticalCenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export { InnerHeader, HeaderWrapper, LogoWrapper, VerticalCenterWrapper };
