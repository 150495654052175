import React, { ReactNode, FC } from 'react';
import styled from 'styled-components';

import Footer from '@components/Layout/Footer/Footer';
import Header from '@components/Layout/Header/Header';
import { GlobalStyles } from '@theme/global';

interface LayoutProps {
  children: ReactNode
}

const MainWrapper = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 0;
`;

const Layout: FC<LayoutProps> = ({ children }) => (
  <>
    <GlobalStyles />
    <Header />
    <MainWrapper>
      <main>{children}</main>
    </MainWrapper>
    <Footer />
  </>
);

export default Layout;
